/* General styling for the support page container */
.support-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    border-radius: 8px;
}

/* Style for headings */
.support-container h2 {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

/* Style for form elements */
.support-container form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.support-container label {
    font-weight: bold;
}

.support-container input[type="email"],
.support-container textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

/* Style for the submit button */
.support-container button {
    background-color: #0056b3;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.support-container button:hover {
    background-color: #004494;
}

/* Style for response message */
.support-container p {
    text-align: center;
    color: green;
}